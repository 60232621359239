import React from "react";
import { render } from "react-dom";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import App from './components/App/App'
// import NotFound from './components/App/NotFound'
// import Home from './components/Home/Home'

import "./styles/main.scss";

render(
  // <Router>
  //   <App>
  //     <Switch>
  //       <Route exact path='/' component={Home} />
  //       <Route path='/:source?/:order?/:template?/:format?' component={Home} />
  //       {/* <Route path='/helloworld' component={HelloWorld} /> */}
  //       <Route component={NotFound} />
  //     </Switch>
  //   </App>
  // </Router>,
  <div>
    <svg width="200" height="200">
      <g>
        <ellipse cx="100" cy="100" rx="160" ry="60" />
        <ellipse
          cx="100"
          cy="100"
          rx="160"
          ry="60"
          transform="rotate(60, 100, 100)"
        />
        <ellipse
          cx="100"
          cy="100"
          rx="160"
          ry="60"
          transform="rotate(120, 100, 100)"
        />
        <circle cx="100" cy="100" r="30" />
      </g>
    </svg>
    <h1>PDF Service v2.9.56</h1>
  </div>,
  document.getElementById("root")
);
